import React, { useState } from 'react';
import { View, StyleSheet, Platform, FlatList } from 'react-native';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { useInterestSwap } from '~/hooks/useInterestSwap';
import InterestRow from './InterestRow';
import DateRow, { getDateRow } from '../Result/DateRow';
import FromToSelector from '../Result/FromToSelector';
import Loader from '~/components/core/Loader';

const now = new Date();
const prevPeriod = new Date(now.getTime() - 30 * 24 * 3600 * 1000);
prevPeriod.setHours(0);
prevPeriod.setMinutes(0);
prevPeriod.setSeconds(0);

export default function InterestSwap() {
    const [fromButton, setFromButton] = useState(prevPeriod);
    const [toButton, setToButton] = useState(now);
    const { interests, totalFormatted, totalDirection, loading, onEndReached, loadingNext } = useInterestSwap(fromButton, toButton);
    const styles = useStyles(stylesGenerator);

    return (
        <>
            <Loader loading={loading} />
            <FlatList
                style={styles.ordersContainer}
                data={interests}
                renderItem={({ item, index }) => (
                    <View style={styles.button} key={index}>
                        {getDateRow(item.timestamp, index) !== '' ? (
                            <DateRow timestamp={item.timestamp} />
                        ) : null}
                        <InterestRow interest={item} />
                    </View>
                )}
                keyExtractor={(item) => item.id}
                key={'interestSwap'}
                initialNumToRender={20}
                onEndReachedThreshold={0.4}
                onEndReached={onEndReached}
            />
            <FromToSelector
                setFromButton={setFromButton}
                setToButton={setToButton}
                prevPeriod={prevPeriod}
                totalFormatted={totalFormatted}
                totalDirection={totalDirection}
            />
        </>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        ordersContainer: {
            paddingTop: scaleSize(7),
            paddingLeft: scaleSize(7),
            paddingRight: Platform.OS === 'web' ? 0 : scaleSize(7),
        },
        button: { width: '100%' },
    });
