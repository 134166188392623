import React, { useState } from 'react';
import { View, StyleSheet, Pressable, Platform } from 'react-native';
import { DateTimePicker, Text } from '~/components/core';
import { useAppSelector, useStyles } from '~/hooks';
import { FONT_SIZE_S, FONT_SIZE_XS } from '~/constants';
import { Theme } from '~/theme';
import { formatCurrencyWithFormatetdNumber, formatDateShort } from '~/lib/format';
import { scaleSize } from '~/constants/scale';
import { accountBalanceSelector } from '~/state/selectors';
import { Ionicons } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';


type Props = {
    setFromButton: Function;
    setToButton: Function;
    prevPeriod: Date;
    totalFormatted?: string;
    totalDirection?: string;
    hideTo?: boolean;
    resultFunction?: Function;
    resultLabel?: string;
};

export default function FromToSelector({
    setFromButton,
    setToButton,
    prevPeriod,
    totalFormatted,
    totalDirection,
    hideTo,
    resultFunction,
    resultLabel
}: Props) {
    const styles = useStyles(stylesGenerator);
    const [from, setFrom] = useState(prevPeriod);
    const [isFromOpen, setIsFromOpen] = useState(false);
    const [to, setTo] = useState(new Date());
    const [isToOpen, setIsToOpen] = useState(false);
    const currency = useAppSelector(accountBalanceSelector)?.currency || '';

    return (
        <>
            <View style={styles.buttonPeriodContainer}>
                <Text fontType="LATO_BOLD" style={styles.buttonPeriodText}>
                    period-to-review
                </Text>
            </View>
            {isFromOpen ? (
                <View style={styles.startDate}>
                    <View style={styles.flex}>
                        <DateTimePicker
                            value={from}
                            onChange={(value) => {
                                setFrom(value ? value : new Date());
                                setFromButton(value ? value : new Date());
                                setToButton(to);
                                setIsFromOpen(false);
                                setIsToOpen(false);
                            }}
                            isOpen={isFromOpen}
                            setIsFromOpen={setIsFromOpen}
                            setIsToOpen={setIsToOpen}
                        />
                    </View>
                </View>
            ) : null}
            {isToOpen ? (
                <View style={styles.endDate}>
                    <View style={styles.flex}>
                        <DateTimePicker
                            value={to}
                            onChange={(value) => {
                                setTo(value ? value : new Date());
                                setToButton(value ? value : new Date());
                                setFromButton(from);
                                setIsFromOpen(false);
                                setIsToOpen(false);
                            }}
                            isOpen={isToOpen}
                            setIsFromOpen={setIsFromOpen}
                            setIsToOpen={setIsToOpen}
                        />
                    </View>
                </View>
            ) : null}
            <View style={styles.datePickerContainer}>
                <View style={styles.datePickerItem}>
                    <Pressable
                        onPress={() => {
                            setIsFromOpen(!isFromOpen);
                            setIsToOpen(false);
                        }}
                        style={styles.fromToContainer}
                    >
                        <Ionicons name="calendar-sharp" style={styles.calendarIcon} size={scaleSize(10)} />
                        <Text fontType="LATO_BOLD" style={styles.datePickerLabel}>
                            set-start-date
                        </Text>
                    </Pressable>
                    <View style={styles.dateContainer}>
                        <Text fontType="LATO_BOLD" style={styles.datePickerLabel}>
                            {from.getTime() ? formatDateShort(from.getTime()) : ''}
                        </Text>
                    </View>
                </View>
                {hideTo !== true ?
                    <View style={[styles.datePickerItem, styles.filterVerticalBorder]}>
                        <Pressable
                            onPress={() => {
                                setIsToOpen(!isToOpen);
                                setIsFromOpen(false);
                            }}
                            style={styles.fromToContainer}
                        >
                            <Ionicons name="calendar-sharp" style={styles.calendarIcon} size={scaleSize(10)} />
                            <Text fontType="LATO_BOLD" style={styles.datePickerLabel}>
                                set-end-date
                            </Text>
                        </Pressable>
                        <View style={styles.dateContainer}>
                            <Text fontType="LATO_BOLD" style={styles.datePickerLabel}>
                                {to.getTime() ? formatDateShort(to.getTime()) : ''}
                            </Text>
                        </View>
                    </View> : null}
                {totalFormatted || totalDirection ? (
                    resultFunction ? (
                        <Pressable style={styles.datePickerItem} onPress={() => resultFunction()}>
                            <View style={styles.dateContainer}>
                                <Text fontType="LATO_BOLD" style={styles.datePickerLabel}>
                                    {resultLabel ? resultLabel : 'result'}
                                </Text>
                                    <Entypo
                                        name={'select-arrows'}
                                        size={scaleSize(12)}
                                        style={styles.dragButton}
                                    />
                            </View>
                            <View style={styles.dateContainer}>
                                <Text
                                    fontType="LATO_BOLD"
                                    style={totalDirection === 'positive' ? styles.positiveResult : styles.negativeResult}
                                >
                                    {formatCurrencyWithFormatetdNumber(totalFormatted, currency)}
                                </Text>
                            </View>
                        </Pressable>
                    ) : (
                        <View style={[styles.datePickerItem, styles.filterVerticalBorder]}>
                            <View style={styles.dateContainer}>
                                <Text fontType="LATO_BOLD" style={styles.datePickerLabel}>
                                    {resultLabel ? resultLabel : 'result'}
                                </Text>
                            </View>
                            <View style={styles.dateContainer}>
                                <Text
                                    fontType="LATO_BOLD"
                                    style={totalDirection === 'positive' ? styles.positiveResult : styles.negativeResult}
                                >
                                    {formatCurrencyWithFormatetdNumber(totalFormatted, currency)}
                                </Text>
                            </View>
                        </View>
                    )
                ) : null}
            </View>
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        dragButton: {
            color: theme.switch_trackOn,
        },
        startDate: {
            flexDirection: 'row',
            width: '100%',
            paddingRight: Platform.OS !== 'ios' ? '52%' : null,
            backgroundColor: theme.backgroundSecondary,
            paddingVertical: scaleSize(4),
        },
        endDate: {
            flexDirection: 'row',
            width: '100%',
            backgroundColor: theme.backgroundSecondary,
            paddingRight: Platform.OS !== 'ios' ? '10%' : null,
            paddingVertical: scaleSize(4),
        },
        flex: { flex: 1 },
        buttonPeriodContainer: {
            width: '100%',
            height: scaleSize(30),
            backgroundColor: theme.background_positionsCount,
            justifyContent: 'center',
            alignItems: 'center',
        },
        resultLabelContainer: { paddingBottom: scaleSize(10) },
        buttonPeriodText: { color: theme.text_priceUp, fontSize: FONT_SIZE_S },
        dateContainer: { paddingBottom: scaleSize(10), flexDirection: 'row' },
        filterVerticalBorder: { borderLeftWidth: 1, borderRightWidth: 1, borderColor: theme.border_2 },
        fromToContainer: { flexDirection: 'row', color: theme.text_positionsCount, paddingBottom: scaleSize(10) },
        calendarIcon: { color: theme.text_positionsCount, paddingRight: scaleSize(7) },

        datePickerContainer: {
            flexDirection: 'row',
        },
        datePickerItem: {
            flex: 1,
            width: scaleSize(125),
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: scaleSize(10),
        },
        datePickerLabel: {
            fontSize: FONT_SIZE_XS,
            color: theme.text_positionsCount,
        },
        positiveResult: { fontSize: FONT_SIZE_XS, color: theme.text_upPrice, letterSpacing: 0.5 },
        negativeResult: { fontSize: FONT_SIZE_XS, color: theme.text_downPrice, letterSpacing: 0.5 },
    });
