import { configureStore } from '@reduxjs/toolkit';

import themeReducer from './reducers/themeSlice';
import userAuthReducer from './reducers/userAuthSlice';
import userDataReducer from './reducers/userDataSlice';
import watchlistReducer from './reducers/watchlistSlice';
import symbolsReducer from './reducers/symbolsSlice';
import appSettingsReducer from './reducers/appSettingsSlice';
import allSymbolsNamesReducer from './reducers/allSymbolNamesSlice';
import avatarsReducer from "./reducers/avatarSlice";

export const store = configureStore({
    reducer: {
        theme: themeReducer,
        userAuth: userAuthReducer,
        userData: userDataReducer,
        watchlist: watchlistReducer,
        symbols: symbolsReducer,
        appSettings: appSettingsReducer,
        allSymbolsNames: allSymbolsNamesReducer,
        avatars: avatarsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: { warnAfter: 128 },
            serializableCheck: { warnAfter: 128 },
        }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type ReduxStore = typeof store;

export type ThunkAPI = {
    state: RootState;
    dispatch?: AppDispatch;
    rejectValue: string;
};
