import React, { useState } from 'react';
import { StyleSheet, Pressable, Platform, FlatList } from 'react-native';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { FONT_SIZE_S } from '~/constants/typography';
import CommissionRow from './CommissionRow';
import { useCommissions } from '~/hooks/useCommissions';
import FromToSelector from '../Result/FromToSelector';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import Loader from '~/components/core/Loader';

const now = new Date();
const prevPeriod = new Date(now.getTime() - 7 * 24 * 3600 * 1000);
prevPeriod.setHours(0);
prevPeriod.setMinutes(0);
prevPeriod.setSeconds(0);

export default function Commissions() {
    const [from, setFrom] = useState(prevPeriod);
    const [to, setTo] = useState(now);
    const { commissions, totalFormatted, totalDirection, loading, onEndReached } = useCommissions(from, to);
    const [openOrder, setOpenOrder] = useState('');
    const styles = useStyles(stylesGenerator);

    return (
        <>
            <Loader loading={loading} />
            <FlatList
                style={styles.ordersContainer}
                data={commissions}
                renderItem={({ item, index }) => (
                    <Pressable
                        onPress={() => setOpenOrder(openOrder === item.id ? '' : item.id)}
                        style={styles.button}
                        key={index}
                    >
                        <CommissionRow commission={item} isOpen={openOrder === item.id} />
                    </Pressable>
                )}
                keyExtractor={(item) => item.id}
                key={'cash'}
                initialNumToRender={20}
                onEndReachedThreshold={0.4}
                onEndReached={onEndReached}
            />
            <FromToSelector
                setFromButton={setFrom}
                setToButton={setTo}
                prevPeriod={prevPeriod}
                totalFormatted={totalFormatted}
                totalDirection={totalDirection}
            />
        </>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        ordersContainer: {
            paddingTop: scaleSize(7),
            paddingLeft: scaleSize(7),
            paddingRight: Platform.OS === 'web' ? 0 : scaleSize(7),
        },
        button: { width: '100%' },
        datePickerContainer: {
            flexDirection: 'row',
        },
        datePickerItem: {
            flex: 1,
            padding: scaleSize(5),
        },
        datePickerLabel: {
            fontSize: FONT_SIZE_S,
        },
    });
