import { useEffect, useState, useCallback } from 'react';
import CopyTradingService from '~/api-services/CopyTradingService';
import { Investor } from '~/api-services/CopyTradingService/types';
import i18n from '~/i18n';
import { ptSelector } from '~/state/selectors';
import { useAppSelector } from "~/hooks/useAppSelector";

export const useInvestors = (isScreenActive: boolean) => {
    const [investors, setInvestors] = useState<Investor[]>(null);
    const [topInvestors, setTopInvestors] = useState<Investor[]>(null);
    const [followedInvestors, setFollowedInvestors] = useState<Investor[]>(null);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [confirmationProps, setConfirmationProps] = useState<{ text: string; error: boolean }>({
        text: '',
        error: false,
    });
    const [loading, setloading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const pt = useAppSelector(ptSelector);
    const [allInvestors, setAllInvestors] = useState(true);

    const changeSearchTerm = useCallback((newSearchTerm: string) => {
        if (searchTerm === newSearchTerm) {
            return;
        }
        setSearchTerm(newSearchTerm);
        changeInvestors(allInvestors, allInvestors, searchTerm, newSearchTerm);
    }, [searchTerm, allInvestors, topInvestors, followedInvestors]);

    const changeAllInvestors = useCallback((newAllInvestors: boolean) => {
        if (allInvestors == newAllInvestors) {
            return;
        }
        setAllInvestors(newAllInvestors);
        changeInvestors(allInvestors, newAllInvestors, searchTerm, searchTerm);
    }, [searchTerm, allInvestors, topInvestors, followedInvestors]);

    async function addFollower(investorUid: string, investorName: string): Promise<string> {
        setloading(true);
        const { status, data } = await CopyTradingService.addFollower(investorUid);
        if (status === 200 && data.status) {
            refreshInvestors();
            setloading(false);
            return '';
        }
        if (status === 200 && !data.status) {
            if (data.errorCode === 'CopyTradingNotEnoughMoney') {
                var messageContext: any = {};
                messageContext.investorName = investorName;
                var message = i18n.t('copy-trading-not-enough-money', messageContext);
                setConfirmationProps({ text: message, error: true });
                setConfirmationVisible(true);
            } else if (data.errorCode === 'CopyTradingMaxFollowing') {
                var messageContext: any = {};
                messageContext.maxFollow = data.errorContext.maxFollow;
                var message = i18n.t('max-follow', messageContext);
                setConfirmationProps({ text: message, error: true });
                setConfirmationVisible(true);
            } else {
                if (data.errorCode !== 'CopyTradingNotConfirmed') {
                    setConfirmationProps({ text: 'error-' + data.errorCode, error: true });
                    setConfirmationVisible(true);
                }
            }
            setloading(false);
            return data.errorCode;
        }
    }

    async function removeFollower(investorUid: string) {
        setloading(true);
        const { status, data } = await CopyTradingService.removeFollower(investorUid);
        if (status === 200 && data.status) {
            refreshInvestors();
        }
        if (status === 200 && !data.status) {
            setConfirmationProps({ text: 'error-' + data.errorCode, error: true });
            setConfirmationVisible(true);
        }
        setloading(false);
    }

    async function changeInvestors(oldAllInvestors: boolean, newAllInvestors: boolean, oldSearchTerm, newSearchTerm: string) {
        if (pt) {
            return;
        }
        if (newAllInvestors) {
            if (newSearchTerm.length < 3) {
                setInvestors(topInvestors);
                if (oldSearchTerm.length >= 3 || !oldAllInvestors) {
                    // if search term or all investors changed
                    // then refresh top investors
                    refreshTopInvestors(newAllInvestors, newSearchTerm);
                }
            } else {
                const { status, data } = await CopyTradingService.search(newSearchTerm);
                if (status === 200 && data.status) {
                    setInvestors(data.returnData);
                } else {
                    setInvestors(null);
                }
            }
        } else {
            setInvestors(filterInvestors(followedInvestors, newSearchTerm));
            if (oldAllInvestors) {
                refreshFollowedInvestors(newAllInvestors, newSearchTerm);
            }
        }
    }

    const refreshInvestors = useCallback(() => {
        if (allInvestors) {
            if (searchTerm.length < 3) {
                refreshTopInvestors(allInvestors, searchTerm);
            } else {
                refreshSearchInvestors(allInvestors, searchTerm);
            }
        } else {
            refreshFollowedInvestors(allInvestors, searchTerm);
        }
    }, [searchTerm, allInvestors, topInvestors, followedInvestors]);

    async function refreshTopInvestors(newAllInvestors: boolean, newSearchTerm: string) {
        if (pt) {
            return;
        }
        const { status, data } = await CopyTradingService.getTopInvestors();
        if (status === 200 && data.status) {
            setTopInvestors(data.returnData);
            if (newAllInvestors && newSearchTerm.length < 3) {
                setInvestors(data.returnData);
            }
        } else {
            setTopInvestors(null);
            if (newAllInvestors && newSearchTerm.length < 3) {
                setInvestors(null);
            }
        }
    }

    async function refreshSearchInvestors(_: boolean, newSearchTerm: string) {
        if (pt) {
            return;
        }
        if (allInvestors && searchTerm.length >= 3) {
            // ok
        } else {
            return;
        }
        const { status, data } = await CopyTradingService.search(newSearchTerm);
        if (status === 200 && data.status) {
            setInvestors(data.returnData);
        } else {
            setInvestors(null);
        }
    }

    async function refreshFollowedInvestors(newAllInvestors: boolean, newSearchTerm: string) {
        if (pt) {
            return;
        }
        const { status, data } = await CopyTradingService.getFollowed();
        if (status === 200 && data.status) {
            setFollowedInvestors(data.returnData);
            if (!newAllInvestors) {
                setInvestors(filterInvestors(data.returnData, newSearchTerm));
            }
        } else {
            setFollowedInvestors(null);
            if (!newAllInvestors) {
                setInvestors(null);
            }
        }
    }

    function filterInvestors(arr: Investor[], name: string): Investor[]{
        if (!name) {
            return arr;
        }
        if (name.length < 3) {
            return arr;
        }
        if (!arr) {
            return null;
        }
        name = name.toLowerCase();
        let result: Investor[] = [];
        arr.forEach(item => {
            if (item.name.toLowerCase().indexOf(name) >= 0) {
                result.push(item);
            }
        });
        return result;
    }

    useEffect(() => {
        if (isScreenActive) {
            refreshInvestors();
        }
    }, [isScreenActive]);

    return {
        investors,
        addFollower,
        removeFollower,
        confirmationVisible,
        setConfirmationVisible,
        confirmationProps,
        setConfirmationProps,
        loading,
        allInvestors,
        searchTerm,
        changeSearchTerm,
        changeAllInvestors
    };
};
