import * as React from 'react';
import { Entypo } from '@expo/vector-icons';
import { isDesktop, scaleSize } from '~/constants/scale';
import { Platform, Pressable, StyleSheet, View, Image } from 'react-native';
import { goBack } from '~/navigation';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { Text } from '../core';
import { FONT_SIZE_L } from '~/constants';
import { Investor } from '~/api-services/CopyTradingService/types';

type Props = {
    investorInfo: Investor;
    addFollower: Function;
    unfollowInvestor: Function;
    setActiveWatchlist: Function;
    setConfirmation: Function;
};

export default function InvestorHeader({
    investorInfo,
    addFollower,
    unfollowInvestor,
    setActiveWatchlist,
    setConfirmation,
}: Props) {
    const styles = useStyles(stylesGenerator);

    async function pressFollower() {
        if (investorInfo.followed === false) {
            let result: string = await addFollower(investorInfo.uid, investorInfo.name);
            if (result === 'CopyTradingNotConfirmed') {
                setConfirmation(true);
            }
        } else {
            unfollowInvestor();
        }
    }

    return (
        <View style={styles.container}>
            <Pressable onPress={() => (isDesktop() ? setActiveWatchlist(-1) : goBack())} style={styles.backContainer}>
                <Entypo size={scaleSize(20)} name="chevron-left" style={styles.backArrow} />
            </Pressable>
            <View style={styles.avatarContainer}>
                <Image
                    source={{
                        uri: investorInfo.avatarUrl,
                    }}
                    style={styles.avatarStyle}
                />
            </View>
            <View style={styles.headerTitle}>
                <Text fontType="LATO_BOLD" style={styles.headerText}>
                    {investorInfo.name && investorInfo.name.length > 20
                        ? investorInfo.name.substring(0, 20) + '...'
                        : investorInfo.name}
                </Text>
            </View>
            <View style={styles.followContainer}>
                <Pressable
                    onPress={() => {
                        pressFollower();
                    }}
                    style={investorInfo.followed ? styles.followButton : styles.unfollowButton}
                >
                    <Entypo
                        name="check"
                        style={investorInfo.followed ? styles.buttonWhite : styles.buttonDark}
                        size={scaleSize(14)}
                    />
                </Pressable>
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        unfollowButton: {
            alignItems: 'center',
            justifyContent: 'center',
            width: scaleSize(22),
            height: scaleSize(22),
            borderRadius: scaleSize(11),
            borderWidth: scaleSize(1),
            borderColor: theme.background_message,
        },
        followButton: {
            alignItems: 'center',
            justifyContent: 'center',
            width: scaleSize(22),
            height: scaleSize(22),
            borderRadius: scaleSize(11),
            backgroundColor: theme.background_message,
        },
        buttonWhite: { color: theme.white },
        buttonDark: { color: theme.background_message },
        followContainer: {
            alignContent: 'center',
            justifyContent: 'center',
            paddingRight: scaleSize(10),
            width: scaleSize(30),
        },
        avatarStyle: { width: scaleSize(60), height: scaleSize(60), borderRadius: scaleSize(5) },
        avatarContainer: { justifyContent: 'center', alignItems: 'center' },
        container: {
            backgroundColor: theme.backgroundSecondary,
            flexDirection: 'row',
            width: '100%',
            paddingHorizontal: scaleSize(5),
            paddingBottom: scaleSize(5),
            paddingTop: scaleSize(5),
        },
        backArrow: { color: theme.text_positionId },
        backContainer: {
            height: scaleSize(50),
            paddingLeft: scaleSize(5),
            justifyContent: 'center',
            alignItems: 'flex-start',
            paddingRight: scaleSize(10),
        },
        headerTitle: {
            width: scaleSize(230),
            justifyContent: 'center',
            alignItems: 'flex-start',
            paddingLeft: scaleSize(10),
        },
        headerText: { color: theme.text_positionId, fontSize: FONT_SIZE_L },
    });
