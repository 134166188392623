import React, { useState } from 'react';
import { StyleSheet, Pressable, Platform, FlatList } from 'react-native';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { useAllOrders } from '~/hooks/useAllOrders';
import OrderRow from './OrderRow';
import FromToSelector from '../Result/FromToSelector';
import Loader from '~/components/core/Loader';

const now = new Date();
const prevPeriod = new Date(now.getTime() - 7 * 24 * 3600 * 1000);
prevPeriod.setHours(0);
prevPeriod.setMinutes(0);
prevPeriod.setSeconds(0);

export default function AllOrders() {
    const [from, setFrom] = useState(prevPeriod);
    const [to, setTo] = useState(now);
    const { orders, loading, onEndReached } = useAllOrders(from, to);
    const [openOrder, setOpenOrder] = useState('');
    const styles = useStyles(stylesGenerator);

    return (
        <>
            <Loader loading={loading} />
            <FlatList
                style={styles.ordersContainer}
                data={orders}
                renderItem={({ item, index }) => (
                    <Pressable
                        onPress={() => setOpenOrder(openOrder === item.id ? '' : item.id)}
                        style={styles.button}
                        key={index}
                    >
                        <OrderRow order={item} isOpen={openOrder === item.id} />
                    </Pressable>
                )}
                keyExtractor={(item) => item.id}
                key={'cash'}
                initialNumToRender={20}
                onEndReachedThreshold={0.4}
                onEndReached={onEndReached}
            />
            <FromToSelector setFromButton={setFrom} setToButton={setTo} prevPeriod={prevPeriod} />
        </>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        ordersContainer: {
            paddingTop: scaleSize(7),
            paddingLeft: scaleSize(7),
            paddingRight: Platform.OS === 'web' ? 0 : scaleSize(7),
        },
        button: { width: '100%' },
    });
