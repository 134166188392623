import React, {useState, useEffect} from 'react';
import { Platform } from 'react-native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import RegistrationScreen from '~/components/screens/RegistrationScreen';
import LoginScreen from '~/components/screens/LoginScreen';
import LoadingScreen from '~/components/screens/LoadingScreen';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import LoginIBScreen from '../screens/LoginIBScreen';
import LoginAsCustomerScreen from '../screens/LoginAsCustomerScreen';
import AutoLoginScreen from '../screens/AutoLoginScreen';
import ConfirmationScreen from '../screens/ConfirmationScreen';
import * as ScreenOrientation from "expo-screen-orientation";
import RegistrationInvestingScreen from '../screens/RegistrationInvestingScreen';


const Stack = createNativeStackNavigator();

export default function AuthStackNavigator() {

    const [orientation, setOrientation] = useState(3);
    useEffect(() => {
        lockOrientation();
    }, [orientation]);

    const lockOrientation = async () => {
        if(Platform.OS !== 'web') {
            await ScreenOrientation.lockAsync(
                ScreenOrientation.OrientationLock.PORTRAIT_UP
            );
            const o = await ScreenOrientation.getOrientationAsync();
            setOrientation(o);
        }
      };

    return (
        <Stack.Navigator initialRouteName={'Login'}>
            <Stack.Screen
                name="Login"
                component={LoginScreen}
                options={{ headerShown: false, orientation: 'portrait' }}
            />
            <Stack.Screen
                name="LoginIB"
                component={LoginIBScreen}
                options={{ headerShown: false, orientation: 'portrait' }}
            />
            <Stack.Screen
                name="LoginAsCustomer"
                component={LoginAsCustomerScreen}
                options={{ headerShown: false, orientation: 'portrait' }}
            />
            <Stack.Screen
                name="AutoLogin"
                component={AutoLoginScreen}
                options={{ headerShown: false, orientation: 'portrait' }}
            />
            <Stack.Screen
                name="Registration"
                component={RegistrationScreen}
                options={{ headerShown: false, orientation: 'portrait' }}
            />
            <Stack.Screen
                name="RegistrationInvesting"
                component={RegistrationInvestingScreen}
                options={{ headerShown: false, orientation: 'portrait' }}
            />
            <Stack.Screen
                name="Confirmation"
                component={ConfirmationScreen}
                options={{ headerShown: false, orientation: 'portrait' }}
            />
            <Stack.Screen
                name="ForgotPassword"
                component={ForgotPasswordScreen}
                options={{ headerShown: false, orientation: 'portrait' }}
            />
            <Stack.Screen
                name="ResetPassword"
                component={ResetPasswordScreen}
                options={{ headerShown: false, orientation: 'portrait' }}
            />
            <Stack.Screen name="Loading" component={LoadingScreen} />
        </Stack.Navigator>
    );
}
