import { useEffect, useState, useRef, useCallback } from 'react';
import ReportsService from '~/api-services/ReportsService';
import {
    Cash,
    PageIdRequestV2, ReportCashResponseV2, ReportFirstRequestV2, ReportLastRequestV2,
    ReportRequestSpecV2, ReportRequestV2,
} from '~/api-services/ReportsService/types';
import { useAppSelector } from './useAppSelector';
import { appSettingsSelector, userIsLoggedInSelector } from '~/state/selectors';
import { useIsAppActive } from "~/hooks/useIsAppActive";

export const useCash = (from: Date, to: Date) => {
    const [cashArray, setCashArray] = useState<Cash[]>([]);
    const [totalFormatted, setTotalFormatted] = useState<string>('');
    const [totalDirection, setTotalDirection] = useState<'positive' | 'negative'>('positive');
    const [loading, setLoading] = useState(true);
    const { language } = useAppSelector(appSettingsSelector);
    const [reportSpec, setReportSpec] = useState<ReportRequestSpecV2>(null);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [firstPageId, setFirstPageId] = useState<PageIdRequestV2>(null);
    const [lastPageId, setLastPageId] = useState<PageIdRequestV2>(null);
    const [lastPageData, setLastPageData] = useState<Cash[]>([]);
    const isAppActive = useIsAppActive();
    const isLoggedIn = useAppSelector(userIsLoggedInSelector);
    const reloadDataPollRef = useRef<NodeJS.Timer>();

    useEffect(() => {
        if (!from || !to) {
            return;
        }
        from.setHours(0);
        from.setMinutes(0);
        from.setSeconds(0);
        from.setMilliseconds(0);
        to.setHours(0);
        to.setMinutes(0);
        to.setSeconds(0);
        to.setMilliseconds(0);
        if (!reportSpec || reportSpec.from != from.getTime() || reportSpec.to != to.getTime()) {
            let spec: ReportRequestSpecV2 = {
                from: from.getTime(),
                language: language.toUpperCase(),
                to: to.getTime(),
            };
            reset();
            setReportSpec(spec);
        }
    }, [from, to]);

    useEffect(() => {
        if (isLoggedIn && isAppActive && reportSpec) {
            let request: ReportRequestV2 = {
                spec: reportSpec,
            };
            loadData(request);
        }
    }, [reportSpec]);

    const onEndReached = useCallback(() => {
        if (isLoggedIn && isAppActive && lastPageData && lastPageData.length > 0) {
            mergeAndSortList(lastPageData);
            setLastPageData([]);
        }
        if (isLoggedIn && isAppActive && hasNextPage && reportSpec) {
            let request: ReportLastRequestV2 = {
                spec: reportSpec,
                lastPageId: lastPageId,
            };
            reloadLastPage(request);
        }
    }, [cashArray, lastPageData]);

    useEffect(() => {
        if (isAppActive && isLoggedIn) {
            reloadDataPollRef.current && clearInterval(reloadDataPollRef.current);
            reloadDataPollRef.current = setInterval(() => {
                let request: ReportFirstRequestV2 = {
                    spec: reportSpec,
                    firstPageId: firstPageId,
                };
                reloadFirstPage(request);
            }, 60000); // refetch 60 seconds
        } else {
            reloadDataPollRef.current && clearInterval(reloadDataPollRef.current);
        }
        return () => {
            reloadDataPollRef.current && clearInterval(reloadDataPollRef.current);
        };
    }, [isLoggedIn, isAppActive, reportSpec, firstPageId]);

    function copyResponse(data: ReportCashResponseV2) {
        setTotalFormatted(data.returnData.totalFormatted);
        setTotalDirection(data.returnData.totalDirection);
    }

    function reset() {
        setCashArray([]);
        setTotalFormatted("");
        setTotalDirection("positive");
        setFirstPageId(null);
        setLastPageId(null);
    }

    async function loadData(request: ReportRequestV2) {
        setLoading(true);
        try {
            const {status, data} = await ReportsService.getReportCashV2(request);
            if (status === 200 && data.status) {
                setCashArray(data.returnData.data);
                copyResponse(data);
                setHasNextPage(data.returnData.hasNextPage);
                setLastPageId(data.returnData.lastPageId);
                setFirstPageId(data.returnData.firstPageId);
                if (data.returnData.hasNextPage) {
                    // load next page
                    let request2: ReportLastRequestV2 = {
                        spec: request.spec,
                        lastPageId: data.returnData.lastPageId,
                    };
                    reloadLastPage(request2);
                }
            }
        } catch (e) {
            console.log("error", e);
            reset();
        }
        setLoading(false);
    }

    async function reloadFirstPage(request: ReportFirstRequestV2) {
        try {
            const { status, data } = await ReportsService.getReportCashV2First(request);
            if (status === 200 && data.status) {
                if (data.returnData.data && data.returnData.data.length > 0) {
                    mergeAndSortList(data.returnData.data);
                }
                copyResponse(data);
                setFirstPageId(data.returnData.firstPageId);
            }
        } catch (e) {
            console.log("error", e);
        }
    }

    async function reloadLastPage(request: ReportLastRequestV2) {
        try {
            const { status, data } = await ReportsService.getReportCashV2Last(request);
            if (status === 200 && data.status) {
                if (data.returnData.data && data.returnData.data.length > 0) {
                    setLastPageData(data.returnData.data);
                }
                copyResponse(data);
                setHasNextPage(data.returnData.hasNextPage);
                setLastPageId(data.returnData.lastPageId);
            }
        } catch (e) {
            console.log("error", e);
        }
    }

    function mergeAndSortList(newList: Cash[]) {
        // convert existing order array to map
        const existingList = cashArray || [];
        let dataMap = existingList.reduce<Map<string, Cash>>(function(map, obj) {
            map.set(obj.id, obj);
            return map;
        }, new Map<string, Cash>());

        // convert added or updated order array to map
        const newOrUpdate = newList || [];
        newOrUpdate.forEach(function(item: Cash) {
            dataMap.set(item.id, item);
        })

        // collect values into array
        let list : Cash[] = [];
        for (let [_, v] of dataMap) {
            list.push(v);
        }

        // sort array newest to oldest
        list.sort(function(a,b) {
            if (a.timestamp > b.timestamp) {
                return -1;
            }
            if (b.timestamp > a.timestamp) {
                return 1;
            }
            return 0;
        });

        if (list && list.length > 0) {
            setCashArray(list);
        }
    }

    return {
        cashArray,
        totalFormatted,
        totalDirection,
        loading,
        onEndReached,
    };
};
