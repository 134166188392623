import config from '~/config';
import axios from '../baseClient';
import {
    TopInvestorResponse,
    FollowerResponse,
    GetResponse,
    OpenedPositionAvatarsResponse,
    AvatarsResponse
} from './types';

class CopyTradingService {
    private static domain = '/api/socialtrading';

    getTopInvestors() {
        return axios.post<TopInvestorResponse>(`${CopyTradingService.domain}/investor/top`, {
            website: config.WEBSITE,
        });
    }

    getFollowed() {
        return axios.post<TopInvestorResponse>(`${CopyTradingService.domain}/investor/followed`, {
            website: config.WEBSITE,
        });
    }

    addFollower(investorUid: string) {
        return axios.post<FollowerResponse>(`${CopyTradingService.domain}/investor/addFollower`, {
            investorUid: investorUid,
            website: config.WEBSITE,
        });
    }

    removeFollower(investorUid: string) {
        return axios.post<FollowerResponse>(`${CopyTradingService.domain}/investor/removeFollower`, {
            investorUid: investorUid,
            website: config.WEBSITE,
        });
    }

    get(investorUid: string) {
        return axios.post<GetResponse>(`${CopyTradingService.domain}/investor/get`, {
            investorUid: investorUid,
            website: config.WEBSITE,
        });
    }

    confirm() {
        return axios.post<GetResponse>(`${CopyTradingService.domain}/account/confirm`, { website: config.WEBSITE });
    }

    search(name: string) {
        return axios.post<TopInvestorResponse>(`${CopyTradingService.domain}/investor/search`, { name: name, website: config.WEBSITE });
    }

    getOpenedPositionAvatars() {
        return axios.post<OpenedPositionAvatarsResponse>(`${CopyTradingService.domain}/investor/openedPositionAvatars`, {
            website: config.WEBSITE,
        });
    }

    getAvatars(uids: string[]) {
        return axios.post<AvatarsResponse>(`${CopyTradingService.domain}/investor/avatars`, {
            website: config.WEBSITE,
            investorUids: uids,
        });
    }

}

export default new CopyTradingService();
