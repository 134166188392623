import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {thunkGenericApiReducerBuilder} from './utils';
import {ThunkAPI} from '../store';
import CopyTradingService from "~/api-services/CopyTradingService";
import {Avatars, OpenedPositionAvatars} from "~/api-services/CopyTradingService/types";

export interface AvatarState extends ResponseMetadata {
    data: OpenedPositionAvatars;
}

const initialState: AvatarState = {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    data: {
        avatarUrls: {},
        defaultAvatarUrl: ''
    },
};

export const loadAvatars = createAsyncThunk<OpenedPositionAvatars, void, ThunkAPI>(
    'avatar/load',
    async (_, thunkAPI) => {
        try {
            const { status, data } = await CopyTradingService.getOpenedPositionAvatars();
            if (status === 401) {
                return thunkAPI.rejectWithValue('Unauthorized');
            }
            if (status !== 200 || !data) {
                return thunkAPI.rejectWithValue(data.errorCode || 'Error');
            }
            return data.returnData;
        } catch (e) {
            return thunkAPI.rejectWithValue(e instanceof Error ? e.message : `Error`);
        }
    }
);

export const getAvatars = createAsyncThunk<Avatars, string[], ThunkAPI>(
    'avatar/get',
    async (params, thunkAPI) => {
        try {
            const { status, data } = await CopyTradingService.getAvatars(params);
            if (status === 401) {
                return thunkAPI.rejectWithValue('Unauthorized');
            }
            if (status !== 200 || !data) {
                return thunkAPI.rejectWithValue(data.errorCode || 'Error');
            }
            return data.returnData;
        } catch (e) {
            return thunkAPI.rejectWithValue(e instanceof Error ? e.message : `Error`);
        }
    }
);

export const avatarSlice = createSlice({
    name: 'avatar',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        thunkGenericApiReducerBuilder(builder, loadAvatars, (state: AvatarState, response: OpenedPositionAvatars) => {
            if (!response.avatarUrls) {
                response.avatarUrls = {};
            }
            state.data = response;
        });
        thunkGenericApiReducerBuilder(builder, getAvatars, (state: AvatarState, response: Avatars) => {
            if (response.avatarUrls) {
                for (let uid in response.avatarUrls) {
                    state.data.avatarUrls[uid] = response.avatarUrls[uid];
                }
            }
        });
    },
});

export const { clearState } = avatarSlice.actions;

export default avatarSlice.reducer;
