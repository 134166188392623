import React, {useState} from 'react';
import { View, StyleSheet, Platform, Pressable, FlatList } from 'react-native';
import { scaleSize } from '~/constants/scale';
import { useAppSelector, useStyles } from '~/hooks';
import { useResult } from '~/hooks/useResult';
import ResultRow from './ResultRow';
import DateRow, { getDateRow } from './DateRow';
import FromToSelector from './FromToSelector';
import Loader from '~/components/core/Loader';
import { accountDetailsSelector } from '~/state/selectors';
import { ptSelector } from '~/state/selectors';

const now = new Date();


export default function Result() {
    
    const accountDetails = useAppSelector(accountDetailsSelector);
    const prevPeriod = new Date(accountDetails.registrationDate ? accountDetails.registrationDate : now.getTime() - 30 * 24 * 3600 * 1000);
    const [fromButton, setFromButton] = useState(prevPeriod);
    
    const [toButton, setToButton] = useState(now);
    const { results, totalFormatted, totalDirection, myTotalFormatted, myTotalDirection, ctTotalFormatted, ctTotalDirection, loading, onEndReached } = useResult(fromButton, toButton);
    const styles = useStyles(stylesGenerator);
    const pt = useAppSelector(ptSelector);
    const labels = pt ? ['all-results'] : ['all-results', 'ct-results', 'my-results'];
    const [labelIndex, setLabelIndex] = useState(0);
    const [openResult, setOpenResult] = useState('');

    function changeLabelIndex() {
        setLabelIndex((labelIndex + 1) % labels.length);
    }

    function getTotalFormatted(resultIndex: number) {
        switch (resultIndex) {
            case 0:
                return totalFormatted
            case 1:
                return ctTotalFormatted
            case 2:
                return myTotalFormatted
            default:
                return totalFormatted;
        }
    }

    function getTotalDirection(resultIndex: number) {
        switch (resultIndex) {
            case 0:
                return totalDirection
            case 1:
                return ctTotalDirection
            case 2:
                return myTotalDirection
            default:
                return totalDirection;
        }
    }

    return (
        <>
            <Loader loading={loading} />
            <FlatList
                style={styles.ordersContainer}
                data={results}
                renderItem={({ item, index }) => (
                    <View style={styles.container} key={index}>
                        {getDateRow(item.timestamp, index) !== '' ? (
                            <DateRow timestamp={item.timestamp} />
                        ) : null}
                        <Pressable
                            onPress={() => item.investorName ? setOpenResult(openResult === item.id ? '' : item.id) : null}
                            style={styles.button}
                            key={index}
                        >
                            <ResultRow result={item}  isOpen={openResult === item.id} />
                        </Pressable>
                    </View>
                )}
                keyExtractor={(item) => item.id}
                key={'result'}
                initialNumToRender={20}
                onEndReachedThreshold={0.4}
                onEndReached={onEndReached}
            />
            <FromToSelector
                setFromButton={setFromButton}
                setToButton={setToButton}
                prevPeriod={prevPeriod}
                totalFormatted={getTotalFormatted(labelIndex)}
                totalDirection={getTotalDirection(labelIndex)}
                resultFunction={pt ? null : changeLabelIndex}
                resultLabel={labels[labelIndex]}
            />
        </>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        button: { width: '100%' },
        ordersContainer: {
            paddingTop: scaleSize(7),
            paddingLeft: scaleSize(7),
            paddingRight: Platform.OS === 'web' ? 0 : scaleSize(7),
        },
        container: { width: '100%' },
    });
