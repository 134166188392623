import axios from '../baseClient';

import { GetSymbolsResponse, GetInitialDataResponse } from './types';

class LoadService {
    private static domain = '/api/initdataservice';

    getInitialData() {
        return axios.get<GetInitialDataResponse>(`${LoadService.domain}/getInitialDataV2`);
    }

    getSymbols(symbols: string[]) {
        return axios.post<GetSymbolsResponse>(`${LoadService.domain}/GetSymbolsV2`, { symbols });
    }
}

export default new LoadService();
