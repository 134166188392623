import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { DataSymbol } from '~/api-services/LoadService/types';
import { FONT_SIZE_XXL, FONT_SIZE_XL, FONT_SIZE_M, FONT_SIZE_S } from '~/constants/typography';
import { useAppDispatch, useAppSelector, useStyles } from '~/hooks';

import { BasicLineChart, Text } from '~/components/core';
import { symbolsSelector, userDataSelector } from '~/state/selectors';
import { loadSymbolsCandles } from '~/state/reducers/symbolsSlice';
import { Theme } from '~/theme';
import { formatNumber } from '~/lib/format';
import { scaleSize } from '~/constants/scale';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import InstrumentIcon from './InstrumentIcon';
import { useTheme } from '~/hooks/useTheme';

type Props = {
    isLarge: boolean;
    symbol: string;
};

const extractDailyChange = (s: DataSymbol) => {
    if (s) {
        const d1 = s.deviation.d1;
        const bid = s.price.bid;
        const dailyChange = d1 && bid ? ((bid - d1) / d1) * 100 : 0;
        return dailyChange;
    }
    return 0;
};

const formatDailyChange = (n: number) => (n < 0 ? `${n.toFixed(3)}%` : `+${n.toFixed(3)}%`);

const getPriceBackgroundColor = (theme: Theme, isMarketOpen: boolean, hasIncreased: boolean) => {
    if (isMarketOpen === false) {
        return '';
    }
    switch (hasIncreased) {
        case true:
            return theme.text_upPrice;
        case false:
            return theme.text_downPrice;
        default:
            return '';
    }
};

const colorPriceTime = 1000;

const getPriceColor = (
    theme: Theme,
    lastPriceBackgroundColor: string,
    isMarketOpen: boolean,
    hasIncreased: boolean
) => {
    if (isMarketOpen === false) {
        return theme.equityValue;
    }
    switch (hasIncreased) {
        case true:
            return theme.text_priceUp;
        case false:
            return theme.text_priceDown;
        default:
            return lastPriceBackgroundColor;
    }
};

export default function MarketChartCard({ symbol, isLarge }: Props) {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const styles = useStyles(stylesGenerator);
    const data = useAppSelector((state) => symbolsSelector(state)[symbol]);
    const openMarket =
        data && data.marketId ? useAppSelector(userDataSelector)?.data?.account.marketToOpeningMap[data.marketId] : 0;
    const now = new Date().getTime();
    const isMarketOpen = openMarket === 0 || now > openMarket;
    const dailyChange = extractDailyChange(data);
    const chartColor = dailyChange >= 0 ? theme.text_upPrice : theme.text_downPrice;
    const dailyChangeColor = dailyChange === 0 ? '' : dailyChange > 0 ? theme.text_upPrice : theme.text_downPrice;
    const [lastPriceBackgroundColorAsk, setLastPriceBackgroundColorAsk] = useState(theme.text_symbol);
    const [lastPriceBackgroundColorBid, setLastPriceBackgroundColorBid] = useState(theme.text_symbol);
    const [priceBackgroundColorAsk, setPriceBackgroundColorAsk] = useState('');
    const [priceBackgroundColorBid, setPriceBackgroundColorBid] = useState('');
    const [priceColorAsk, setPriceColorAsk] = useState('');
    const [priceColorBid, setPriceColorBid] = useState('');
    const iconUrl = data ? data.iconUrl : undefined;

    useEffect(() => {
        let bcolor = getPriceBackgroundColor(theme, isMarketOpen, data ? data.askPriceHasIncreased : undefined);
        setPriceBackgroundColorAsk(bcolor);
        setPriceColorAsk(
            getPriceColor(
                theme,
                lastPriceBackgroundColorAsk,
                isMarketOpen,
                data ? data.askPriceHasIncreased : undefined
            )
        );
        if (bcolor !== '' && bcolor !== theme.text_symbol) {
            setLastPriceBackgroundColorAsk(bcolor);
            if (data.askPriceHasIncreased === true || data.askPriceHasIncreased === false) {
                setTimeout(() => {
                    setPriceBackgroundColorAsk('');
                    setPriceColorAsk(bcolor);
                }, colorPriceTime);
            }
        }
    }, [data, isMarketOpen, lastPriceBackgroundColorAsk, theme]);

    useEffect(() => {
        let bcolor = getPriceBackgroundColor(theme, isMarketOpen, data ? data.bidPriceHasIncreased : undefined);
        setPriceBackgroundColorBid(bcolor);
        setPriceColorBid(
            getPriceColor(
                theme,
                lastPriceBackgroundColorBid,
                isMarketOpen,
                data ? data.bidPriceHasIncreased : undefined
            )
        );
        if (bcolor !== '' && bcolor !== theme.text_symbol) {
            setLastPriceBackgroundColorBid(bcolor);
            if (data.bidPriceHasIncreased === true || data.bidPriceHasIncreased === false) {
                setTimeout(() => {
                    setPriceBackgroundColorBid('');
                    setPriceColorBid(bcolor);
                }, colorPriceTime);
            }
        }
    }, [data, isMarketOpen, lastPriceBackgroundColorBid, theme]);

    useEffect(() => {
        if (data !== undefined) {
            if (data.chartData) {
                return;
            }
            dispatch(loadSymbolsCandles([data.symbol]));
        }
    }, [dispatch, data]);

    if (data === undefined) {
        return <View />;
    }

    return (
        <Pressable
            style={[
                styles.container,
                isLarge ? styles.largeContainer : styles.smallContainer,
                { backgroundColor: theme.backgroundSecondary },
            ]}
            onPress={() => linkTo(paths.buySellPosition.replace(':symbol', data.symbol))}
        >
            {isLarge ? (
                <View style={styles.symbolContainerLarge}>
                    <View style={styles.instrumentIconRow}>
                        {iconUrl && iconUrl !== '' && <InstrumentIcon iconUrl={iconUrl} />}
                    </View>
                    <View style={styles.instrumentName}>
                        <Text
                            fontType="LATO_BOLD"
                            style={[
                                styles.extraLargeText,
                                { color: isMarketOpen ? theme.text_symbol : theme.equityValue },
                            ]}
                        >
                            {data.nameTranslated}
                        </Text>
                    </View>
                    <View style={styles.instrumentIconRow} />
                </View>
            ) : (
                <View style={styles.toolbar}>
                    <View style={styles.symbolContainer}>
                        <Text
                            fontType="LATO_BOLD"
                            style={[styles.smallText, { color: isMarketOpen ? theme.text_symbol : theme.equityValue }]}
                        >
                            {data.nameTranslated}
                        </Text>
                    </View>
                    <View style={styles.rightAligned}>
                        <View
                            style={[
                                styles.priceContainer,
                                isMarketOpen && priceBackgroundColorBid !== '' ? {
                                    backgroundColor:
                                        priceBackgroundColorBid
                                } : null,
                            ]}
                        >
                            <Text
                                fontType="LATO_BOLD"
                                style={[
                                    styles.mediumText,
                                    priceColorBid && priceColorBid !== '' ? {
                                        color: priceColorBid,
                                    } : null,
                                ]}
                            >
                                {formatNumber(data.price.bid, data.digits)}
                            </Text>
                        </View>
                        <View style={styles.dailyChangeContainerSmall}>
                            <Text
                                fontType="LATO_BOLD"
                                style={[
                                    styles.dailyChangeSmallText,
                                    dailyChangeColor ? {
                                        color: dailyChangeColor,
                                    } : null,
                                ]}
                            >
                                {formatDailyChange(dailyChange)}
                            </Text>
                        </View>
                    </View>
                </View>
            )}
            <BasicLineChart {...data.chartData} isLarge={isLarge} chartColor={chartColor} />
            {isLarge && (
                <View style={[styles.toolbar, styles.overChartStyle]}>
                    <Pressable
                        onPress={() =>
                            linkTo(paths.marketOrder.replace(':symbol', data.symbol).replace(':side', 'sell'))
                        }
                    >
                        <View style={styles.buySellContainer}>
                            <Text fontType="LATO_BOLD" style={[styles.mediumText, { color: theme.text_downPrice }]}>
                                SELL
                            </Text>
                        </View>
                        <View
                            style={[
                                styles.priceContainerLarge,
                                {
                                    backgroundColor:
                                        isMarketOpen && priceBackgroundColorBid !== '' ? priceBackgroundColorBid : null,
                                },
                            ]}
                        >
                            <Text fontType="LATO_BOLD" style={[styles.extraXLargeText, priceColorBid && priceColorBid !== '' ? { color: priceColorBid } : null]}>
                                {formatNumber(data.price.bid, data.digits)}
                            </Text>
                        </View>
                    </Pressable>
                    <View style={styles.dailyChangeLarge}>
                        <Text
                            fontType="LATO_REGULAR"
                            style={[
                                styles.dailyChangeLargeText,
                                dailyChangeColor ? {
                                    color: dailyChangeColor,
                                } : null,
                            ]}
                        >
                            {formatDailyChange(dailyChange)}
                        </Text>
                    </View>
                    <Pressable
                        style={styles.rightAligned}
                        onPress={() =>
                            linkTo(paths.marketOrder.replace(':symbol', data.symbol).replace(':side', 'buy'))
                        }
                    >
                        <View style={styles.buySellContainer}>
                            <Text fontType="LATO_BOLD" style={[styles.mediumText, { color: theme.text_upPrice }]}>
                                BUY
                            </Text>
                        </View>
                        <View
                            style={[
                                styles.priceContainerLarge,
                                isMarketOpen && priceBackgroundColorAsk !== '' ? {
                                    backgroundColor:
                                        priceBackgroundColorAsk,
                                } : null,
                            ]}
                        >
                            <Text fontType="LATO_BOLD" style={[styles.extraXLargeText, priceColorAsk && priceColorAsk !== '' ? { color: priceColorAsk } : null]}>
                                {formatNumber(data.price.ask, data.digits)}
                            </Text>
                        </View>
                    </Pressable>
                </View>
            )}
        </Pressable>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        overChartStyle: { position: 'absolute', top: scaleSize(115), paddingHorizontal: scaleSize(12) },
        container: {
            flex: 1,
            alignItems: 'center',
            paddingHorizontal: scaleSize(10),
            paddingTop: scaleSize(10),
            borderTopLeftRadius: scaleSize(5),
            borderTopRightRadius: scaleSize(5),
            marginTop: scaleSize(7),
            marginRight: scaleSize(7),
        },
        dailyChangeLarge: {
            height: '100%',
            justifyContent: 'flex-end',
        },
        largeContainer: {
            maxWidth: '100%',
            minHeight: scaleSize(171),
            maxHeight: scaleSize(171),
        },
        smallContainer: {
            maxWidth: '48.13%',
            minHeight: scaleSize(91),
            maxHeight: scaleSize(91),
        },
        extraLargeText: {
            fontSize: FONT_SIZE_XL,
        },
        extraXLargeText: {
            fontSize: FONT_SIZE_XXL,
        },
        mediumText: {
            fontSize: FONT_SIZE_M,
        },
        dailyChangeContainerSmall: {
            paddingVertical: scaleSize(2),
        },
        dailyChangeSmallText: {
            fontSize: FONT_SIZE_S,
            letterSpacing: 0.5,
        },
        dailyChangeLargeText: {
            fontSize: FONT_SIZE_M,
            letterSpacing: 0.5,
        },
        smallText: {
            fontSize: FONT_SIZE_S,
        },
        toolbar: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            maxHeight: scaleSize(34),
        },
        rightAligned: {
            alignItems: 'flex-end',
        },
        priceContainer: { borderRadius: scaleSize(5), padding: scaleSize(2) },
        priceContainerLarge: { borderRadius: scaleSize(5), padding: scaleSize(2) },
        symbolContainer: { height: '100%' },
        instrumentIcon: { height: scaleSize(28), width: scaleSize(28) },
        instrumentIconCurrency1: { height: scaleSize(24), width: scaleSize(24) },
        instrumentIconCurrency2: {
            position: 'absolute',
            left: scaleSize(16),
            height: scaleSize(24),
            width: scaleSize(24),
        },
        instrumentIconRow: { width: '20%', height: scaleSize(30), justifyContent: 'center' },
        instrumentName: { height: scaleSize(30), alignItems: 'center', justifyContent: 'center' },
        symbolContainerLarge: {
            paddingBottom: scaleSize(10),
            justifyContent: 'space-between',
            flexDirection: 'row',
            width: '100%',
            height: scaleSize(40),
        },
        buySellContainer: {
            borderRadius: scaleSize(3),
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.white,
            width: scaleSize(40),
            height: scaleSize(18),
            marginBottom: scaleSize(8),
        },
    });
