import { createSlice, createAsyncThunk, ThunkDispatch, AnyAction } from '@reduxjs/toolkit';

import { AuthDataService } from '~/api-services';
import type {
    AutoLoginParams,
    LoginIBParams,
    LoginParams,
    LoginResponseReturnData,
} from '~/api-services/AuthDataService/types';
import { thunkGenericApiReducerBuilder } from './utils';
import LocalStorage from '../localStorage';
import {
    setupAuthHeaders,
    clearAuthHeaders,
    setupSessionRefetchInterceptor,
    ejectSessionRefetchInterceptor,
} from '~/api-services/baseClient';
import type { RootState, ThunkAPI } from '../store';
import { clearState as clearUserDataState } from './userDataSlice';
import { clearState as clearWatchlistSliceState } from './watchlistSlice';
import { clearState as clearAvatarSliceState } from './avatarSlice';

export interface UserAuthState extends ResponseMetadata {
    data: LoginResponseReturnData;
    localDataIsSet: boolean;
}

const initialState: UserAuthState = {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    data: {
        sessionId: '',
        language: 'en',
        email: '',
        type: 'UNKNOWN',
        token: '',
        accountStatus: 'UNKNOWN',
        registrationStatus: 'UNKNOWN',
        oldVersion: false,
    },
    localDataIsSet: false,
};

const setupSessionRefetch = (data: LoginResponseReturnData, dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    if (!data.token) {
        return;
    }

    setupSessionRefetchInterceptor(
        data.token,
        (authData) => dispatch(resetLocalUserAuthData(authData)),
        () => dispatch(logoutUser())
    );
};

export const autoLogin = createAsyncThunk<LoginResponseReturnData, AutoLoginParams, ThunkAPI>(
    'userAuth/autoLogin',
    async (params, thunkAPI) => {
        try {
            const { status, data } = await AuthDataService.autoLogin(params);
            if (status === 200 && data.returnData) {
                await LocalStorage.userAuthData.set(data.returnData);
                setupAuthHeaders(data.returnData.sessionId, data.returnData.language);
                setupSessionRefetch(data.returnData, thunkAPI.dispatch);
                return data.returnData;
            } else {
                return thunkAPI.rejectWithValue(data.errorCode || 'Unauthorized');
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e instanceof Error ? e.message : `Unauthorized`);
        }
    }
);

export const loginIBUser = createAsyncThunk<LoginResponseReturnData, LoginIBParams, ThunkAPI>(
    'userAuth/loginIB',
    async (params, thunkAPI) => {
        try {
            const { status, data } = await AuthDataService.loginIB(params);
            if (status === 200 && data.returnData) {
                await LocalStorage.userAuthData.set(data.returnData);
                setupAuthHeaders(data.returnData.sessionId, data.returnData.language);
                setupSessionRefetch(data.returnData, thunkAPI.dispatch);
                return data.returnData;
            } else {
                return thunkAPI.rejectWithValue(data.errorCode || 'Unauthorized');
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e instanceof Error ? e.message : `Unauthorized`);
        }
    }
);

export const loginAsCustomer = createAsyncThunk<LoginResponseReturnData, LoginIBParams, ThunkAPI>(
    'userAuth/loginAsCustomer',
    async (params, thunkAPI) => {
        try {
            const { status, data } = await AuthDataService.loginAsCustomer(params);
            if (status === 200 && data.returnData) {
                await LocalStorage.userAuthData.set(data.returnData);
                setupAuthHeaders(data.returnData.sessionId, data.returnData.language);
                setupSessionRefetch(data.returnData, thunkAPI.dispatch);
                return data.returnData;
            } else {
                return thunkAPI.rejectWithValue(data.errorCode || 'Unauthorized');
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e instanceof Error ? e.message : `Unauthorized`);
        }
    }
);

export const loginUser = createAsyncThunk<LoginResponseReturnData, LoginParams, ThunkAPI>(
    'userAuth/login',
    async (params, thunkAPI) => {
        try {
            const { status, data } = await AuthDataService.login(params);
            if (status === 200 && data.returnData) {
                await LocalStorage.userAuthData.set(data.returnData);
                setupAuthHeaders(data.returnData.sessionId, data.returnData.language);
                setupSessionRefetch(data.returnData, thunkAPI.dispatch);
                return data.returnData;
            } else {
                return thunkAPI.rejectWithValue(data.errorCode || 'Unauthorized');
            }
        } catch (e) {
            let errorValue = 'Unauthorized';
            if (e instanceof Error) {
                errorValue = e.message;
            }
            if (e.response.data.errorCode) {
                errorValue = e.response.data.errorCode;
            }
            return thunkAPI.rejectWithValue(errorValue);
        }
    }
);

export const loadLocalUserAuthData = createAsyncThunk<LoginResponseReturnData | null, void, ThunkAPI>(
    'userAuth/loadLocal',
    async (_, thunkAPI) => {
        const data = await LocalStorage.userAuthData.get();

        if (data) {
            setupAuthHeaders(data.sessionId, data.language);
            setupSessionRefetch(data, thunkAPI.dispatch);
        }

        return data;
    }
);

export const resetLocalUserAuthData = createAsyncThunk<LoginResponseReturnData, LoginResponseReturnData>(
    'userAuth/resetLocal',
    async (params) => {
        await LocalStorage.userAuthData.set(params);
        return params;
    }
);

export const logoutUser = createAsyncThunk<void, void, ThunkAPI>('userAuth/logout', async (_, thunkAPI) => {
    try {
        await AuthDataService.logout();
    } catch (e) {
        // do nothing
    }
    await LocalStorage.userAuthData.clear();
    clearAuthHeaders();
    ejectSessionRefetchInterceptor();
    thunkAPI.dispatch(clearUserDataState());
    thunkAPI.dispatch(clearWatchlistSliceState());
    thunkAPI.dispatch(clearAvatarSliceState());
});

export const userAuthSlice = createSlice({
    name: 'userAuth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        thunkGenericApiReducerBuilder(builder, loginUser);
        thunkGenericApiReducerBuilder(builder, loginIBUser);
        thunkGenericApiReducerBuilder(builder, loginAsCustomer);
        thunkGenericApiReducerBuilder(builder, autoLogin);

        builder.addCase(loadLocalUserAuthData.fulfilled, (state, { payload }) => ({
            ...state,
            data: payload || state.data,
            localDataIsSet: true,
        }));

        builder.addCase(resetLocalUserAuthData.fulfilled, (state, { payload }) => ({
            ...state,
            data: payload,
        }));

        builder.addCase(logoutUser.fulfilled, () => ({ ...initialState, localDataIsSet: true }));
    },
});

export default userAuthSlice.reducer;
