import { useState, useEffect } from 'react';
import { Platform } from 'react-native';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';

import PushnotificationsService from '~/api-services/PushnotificationsService';
import { useAppSelector } from './useAppSelector';
import { userIsLoggedInSelector } from '~/state/selectors';
import config from '~/config';

export const usePushNotifications = (isAppLoading: boolean) => {
    const [expoPushToken, setExpoPushToken] = useState<string | null>(null);
    const [expoPushTokenDevice, setExpoPushTokenDevice] = useState<string | null>(null);
    const [listenersAreReady, setListenersAreReady] = useState(false);
    const [hasRegisteredToken, setHasRegisteredToken] = useState(false);
    const isLoggedIn = useAppSelector(userIsLoggedInSelector);

    useEffect(() => {
        if (!isLoggedIn) {
            return;
        }

        Notifications.setNotificationHandler({
            handleNotification: async () => ({
                shouldShowAlert: true,
                shouldPlaySound: false,
                shouldSetBadge: false,
            }),
        });
        if (Platform.OS === 'android' && config.WEBSITE === 'FXMERIDIAN') {
            getPushNotificationTokenDevice().then(setExpoPushTokenDevice);
        } else {
            getPushNotificationToken().then(setExpoPushToken);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (!expoPushToken) {
            return;
        }

        if (Platform.OS === 'android') {
            Notifications.setNotificationChannelAsync('default', {
                name: 'default',
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: '#FF231F7C',
            });
        }

        // This listener is fired whenever a notification is received while the app is foregrounded
        const notificationListener = Notifications.addNotificationReceivedListener((notif) => {
            // setNotification(notif);
        });

        // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
        const responseListener = Notifications.addNotificationResponseReceivedListener((res) => {});

        setListenersAreReady(true);

        return () => {
            Notifications.removeNotificationSubscription(notificationListener);
            Notifications.removeNotificationSubscription(responseListener);
        };
    }, [expoPushToken]);

    useEffect(() => {
        if (!expoPushTokenDevice) {
            return;
        }

        if (Platform.OS === 'android') {
            Notifications.setNotificationChannelAsync('default', {
                name: 'default',
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: '#FF231F7C',
            });
        }

        // This listener is fired whenever a notification is received while the app is foregrounded
        const notificationListener = Notifications.addNotificationReceivedListener((notif) => {
            // setNotification(notif);
        });

        // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
        const responseListener = Notifications.addNotificationResponseReceivedListener((res) => {});

        setListenersAreReady(true);

        return () => {
            Notifications.removeNotificationSubscription(notificationListener);
            Notifications.removeNotificationSubscription(responseListener);
        };
    }, [expoPushTokenDevice]);

    useEffect(() => {
        if (!expoPushToken || !listenersAreReady) {
            return;
        }

        PushnotificationsService.registerToken(expoPushToken).then((res) => {
            if (res.status !== 200 || !res.data.status) {
                return;
            }
            setHasRegisteredToken(true);
        });
    }, [expoPushToken, listenersAreReady]);

    useEffect(() => {
        if (!expoPushTokenDevice || !listenersAreReady) {
            return;
        }

        PushnotificationsService.registerTokenDevice(expoPushTokenDevice, Platform.OS.toString()).then((res) => {
            if (res.status !== 200 || !res.data.status) {
                return;
            }
            setHasRegisteredToken(true);
        });
    }, [expoPushTokenDevice, listenersAreReady]);

    useEffect(() => {
        if (isAppLoading || isLoggedIn || !expoPushToken) {
            return;
        }

        // On logout:
        setExpoPushToken(null);
        // TODO: check if this is enough when logging a different user
        // or we need to reset the notification listeners on logout
        setHasRegisteredToken(false);
        PushnotificationsService.unregisterToken(expoPushToken);
    }, [isAppLoading, isLoggedIn, expoPushToken]);

    useEffect(() => {
        if (isAppLoading || isLoggedIn || !expoPushTokenDevice) {
            return;
        }

        // On logout:
        setExpoPushTokenDevice(null);
        // TODO: check if this is enough when logging a different user
        // or we need to reset the notification listeners on logout
        setHasRegisteredToken(false);
        PushnotificationsService.unregisterTokenDevice(expoPushTokenDevice);
    }, [isAppLoading, isLoggedIn, expoPushToken]);

    return hasRegisteredToken;
};

const getPushNotificationToken = async () => {
    try {
        if (!Constants.isDevice || Platform.OS === 'web') {
            return null;
        }
        let status = await (await Notifications.getPermissionsAsync()).status;
        if (status !== 'granted') {
            status = await (await Notifications.requestPermissionsAsync()).status;
        }
        if (status !== 'granted') {
            return null;
        }
        const { data: token } = await Notifications.getExpoPushTokenAsync({ projectId: config.PROJECT_ID});
        return token;
    } catch (error) {
        return null;
    }
};

const getPushNotificationTokenDevice = async () => {
    try {
        if (!Constants.isDevice || Platform.OS === 'web') {
            return null;
        }
        let status = await (await Notifications.getPermissionsAsync()).status;
        if (status !== 'granted') {
            status = await (await Notifications.requestPermissionsAsync()).status;
        }
        if (status !== 'granted') {
            return null;
        }
        const { data: token } = await Notifications.getDevicePushTokenAsync();
        return token;
    } catch (error) {
        return null;
    }
};
