import { createSelector } from '@reduxjs/toolkit';
import type { DataSymbol, Position } from '~/api-services/LoadService/types';

import type { RootState } from './store';

export const themeSelector = (state: RootState) => state.theme.value;

export const userAuthSelector = (state: RootState) => state.userAuth;
export const userLocalDataIsSetSelector = (state: RootState) => state.userAuth.localDataIsSet;
export const userCredentialsAreSetSelector = ({
    userAuth: {
        data: { sessionId, language, email },
    },
}: RootState) => !!(sessionId && email && language);
export const userAuthDataSelector = (state: RootState) => state.userAuth.data;
export const userIsLoggedInSelector = ({
    userAuth: {
        data: { sessionId, language, email },
    },
    userData,
}: RootState) => !!(sessionId && email && language && userData.data);

export const userDataSelector = (state: RootState) => state.userData;
export const pendingMessagesCountSelector = (state: RootState) => state.userData.data?.pendingMessagesCount;

export const accountBalanceSelector = (state: RootState) => state.userData.data?.account.balanceValues;
export const accountDetailsSelector = (state: RootState) => state.userData.data?.accountDetails;
export const accountPositionsSelector = (state: RootState) => state.userData.data?.account.positions || {};
export const accountPendingMessagesSelector = (state: RootState) => state.userData.data?.account.pendingMessages || [];
export const dealerSelector = (state: RootState) => state.userData.data?.accountDetails.dealer;
export const ptSelector = (state: RootState) => state.userData.data?.accountDetails.pt;

export const traderIdSelector = (state: RootState) => state.userData.data?.traderId;

export const allSymbolNamesSelector = (state: RootState) => state.allSymbolsNames.data;

export const watchlistDataSelector = (state: RootState) => state.watchlist.data;
export const favoritesDataSelector = (state: RootState) => state.watchlist.data[0];

export const watchlistSelector = (state: RootState) => state.watchlist;

export const watchlistByIdSelector = (state: RootState, id: number) => state.watchlist.data.find((wi) => wi.id === id);

export const symbolsSelector = (state: RootState) => state.symbols.data;

export const appSettingsSelector = (state: RootState) => state.appSettings.data;
export const appSettingsLanguageSelector = (state: RootState) => state.appSettings.data.language;
export const avatarsSelector = (state: RootState) => state.avatars.data;

export const positionByIdSelector = (state: RootState, id: string): Position | undefined => {
    const positions = state.userData.data?.account.positions;
    for (const key in positions) {
        for (const posKey in positions[key]) {
            if (positions[key][posKey].id === id) {
                return positions[key][posKey];
            }
        }
    }
};

export const positionBy2IdSelector = (state: RootState, id: string, newId: string): Position | undefined => {
    const positions = state.userData.data?.account.positions;
    for (const key in positions) {
        for (const posKey in positions[key]) {
            if (positions[key][posKey].id === id || positions[key][posKey].id === newId ) {
                return positions[key][posKey];
            }
        }
    }
};

export const watchlistInstrumentsSelectorV2 = createSelector(
    [watchlistByIdSelector],
    (whatchlist) => whatchlist?.instrumentCodes || []
);

export const watchlistInstrumentsSelector = createSelector(
    [watchlistByIdSelector, symbolsSelector],
    (whatchlist, symbols) => {
        const missingSymbols: string[] = [];
        const watchlistInstruments: DataSymbol[] = [];
        if (whatchlist) {
            for (const ic of whatchlist.instrumentCodes) {
                if (symbols[ic]) {
                    watchlistInstruments.push(symbols[ic]);
                } else {
                    missingSymbols.push(ic);
                }
            }
        }
        return {
            watchlistInstruments,
            missingSymbols,
        };
    }
);
