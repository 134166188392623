import config from '~/config';
import axios from '../baseClient';
import {
    GenerateSwapReportResponse,
    InvestorResultsResponse,
    ReportCashResponseV2,
    ReportCommissionsResponseV2, ReportFirstRequestV2,
    ReportInterestResponseV2, ReportLastRequestV2,
    ReportOrdersResponseV2, ReportRequestV2,
    ReportResultResponseV2,
    SwapReportStatusResponse,
} from './types';

class ReportsService {
    private static domain = '/api/24yieldreporting';

    getReportOrdersV2(reportOrdersRequest: ReportRequestV2) {
        return axios.post<ReportOrdersResponseV2>(`${ReportsService.domain}/getReportOrdersV2`, reportOrdersRequest);
    }

    getReportOrdersV2First(reportOrdersRequest: ReportFirstRequestV2) {
        return axios.post<ReportOrdersResponseV2>(`${ReportsService.domain}/getReportOrdersV2First`, reportOrdersRequest);
    }

    getReportOrdersV2Last(reportOrdersRequest: ReportLastRequestV2) {
        return axios.post<ReportOrdersResponseV2>(`${ReportsService.domain}/getReportOrdersV2Last`, reportOrdersRequest);
    }

    getReportResultsV2(reportOrdersRequest: ReportRequestV2) {
        return axios.post<ReportResultResponseV2>(`${ReportsService.domain}/getReportResultsV2`, reportOrdersRequest);
    }

    getReportResultsV2First(reportOrdersRequest: ReportFirstRequestV2) {
        return axios.post<ReportResultResponseV2>(`${ReportsService.domain}/getReportResultsV2First`, reportOrdersRequest);
    }

    getReportResultsV2Last(reportOrdersRequest: ReportLastRequestV2) {
        return axios.post<ReportResultResponseV2>(`${ReportsService.domain}/getReportResultsV2Last`, reportOrdersRequest);
    }

    getReportCashV2(reportOrdersRequest: ReportRequestV2) {
        return axios.post<ReportCashResponseV2>(`${ReportsService.domain}/getReportCashV2`, reportOrdersRequest);
    }

    getReportCashV2First(reportOrdersRequest: ReportFirstRequestV2) {
        return axios.post<ReportCashResponseV2>(`${ReportsService.domain}/getReportCashV2First`, reportOrdersRequest);
    }

    getReportCashV2Last(reportOrdersRequest: ReportLastRequestV2) {
        return axios.post<ReportCashResponseV2>(`${ReportsService.domain}/getReportCashV2Last`, reportOrdersRequest);
    }

    getReportInterestsV3(reportOrdersRequest: ReportRequestV2) {
        return axios.post<ReportInterestResponseV2>(`${ReportsService.domain}/getReportInterestsV3`, reportOrdersRequest);
    }

    getReportInterestsV3First(reportOrdersRequest: ReportFirstRequestV2) {
        return axios.post<ReportInterestResponseV2>(`${ReportsService.domain}/getReportInterestsV3First`, reportOrdersRequest);
    }

    getReportInterestsV3Last(reportOrdersRequest: ReportLastRequestV2) {
        return axios.post<ReportInterestResponseV2>(`${ReportsService.domain}/getReportInterestsV3Last`, reportOrdersRequest);
    }

    getReportCommissionsV2(reportOrdersRequest: ReportRequestV2) {
        return axios.post<ReportCommissionsResponseV2>(
            `${ReportsService.domain}/getReportCommissionsV2`,
            reportOrdersRequest
        );
    }

    getReportCommissionsV2First(reportOrdersRequest: ReportFirstRequestV2) {
        return axios.post<ReportCommissionsResponseV2>(
            `${ReportsService.domain}/getReportCommissionsV2First`,
            reportOrdersRequest
        );
    }

    getReportCommissionsV2Last(reportOrdersRequest: ReportLastRequestV2) {
        return axios.post<ReportCommissionsResponseV2>(
            `${ReportsService.domain}/getReportCommissionsV2Last`,
            reportOrdersRequest
        );
    }

    getInvestorResults(investorUid: string, language: string) {
        return axios.post<InvestorResultsResponse>(
            `${ReportsService.domain}/getInvestorResults`,
            {investorUid: investorUid, language: language, website: config.WEBSITE}
        );
    }

    generateSwapReport(sessionId: string, from: number, to: number, language: string) {
        return axios.get<GenerateSwapReportResponse>(
            `${ReportsService.domain}/generateSwapReport/?sessionId=` + sessionId + '&from=' + from + "&to=" + to + "&language=" + language
        );
    }

    reportStatus(id: string) {
        return axios.get<SwapReportStatusResponse>(
            `${ReportsService.domain}/reportStatus/?id=` + id
        );
    }
}

export default new ReportsService();
