import React, { useState } from 'react';
import { View, StyleSheet, Platform, useWindowDimensions } from 'react-native';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import { scaleSize } from '~/constants/scale';
import { useIsScreenActive, useStyles } from '~/hooks';
import { useInvestors } from '~/hooks/useInvestors';
import ConfirmationMessage from '../../ConfirmationMessage';
import InvestorCard from './InvestorCard';
import TopInvestorsHeader from './TopInvestorsHeader';
import ConfirmationCopyTrading from '../ConfirmationCopyTrading';
import { Theme } from '~/theme';
import YesNoMessage from '../../YesNoMessage';
import Loader from '~/components/core/Loader';


type Props = {
    setActiveWatchlist?: Function;
    setSelectedId?: Function;
};

export default function Investors({ setSelectedId, setActiveWatchlist }: Props) {
    const styles = useStyles(stylesGenerator);
    const isScreenActive = useIsScreenActive();
    const [confirmation, setConfirmation] = useState(false);
    const investorHook = useInvestors(isScreenActive);
    const [returnOptions, setReturnOptions] = useState('12m');
    const [yesNoVisible, setYesNoVisible] = useState(false);
    const dimensions = useWindowDimensions();
    const [yesNoText, setYesNoText] = useState('');
    const [unfollowInvestorUID, setUnfollowInvestorUID] = useState('');
    const [followInvestorUID, setFollowInvestorUID] = useState('');


    function unfollowInvestor() {
        investorHook.removeFollower(unfollowInvestorUID);
        setYesNoVisible(false);
    }

    if (confirmation) {
        return <ConfirmationCopyTrading setConfirmation={setConfirmation} followInvestorUID={followInvestorUID} addFollower={investorHook.addFollower}/>;
    } else {
        return (
            <>
                <Loader loading={investorHook.loading && isScreenActive} />
                <ScrollbarWrapper>
                    <View style={styles.container}>
                        <TopInvestorsHeader
                            returnOptions={returnOptions}
                            setReturnOptions={setReturnOptions}
                            investorHook={investorHook}
                        />
                        {investorHook.investors &&
                            investorHook.investors.length !== 0 &&
                            investorHook.investors.map((investor, key) => (
                                <InvestorCard
                                    setConfirmation={setConfirmation}
                                    setActiveWatchlist={setActiveWatchlist}
                                    allInvestors={investorHook.allInvestors}
                                    returnOptions={returnOptions}
                                    setSelectedId={setSelectedId}
                                    investor={investor}
                                    key={key}
                                    addFollower={investorHook.addFollower}
                                    setYesNoVisible={setYesNoVisible}
                                    setYesNoText={setYesNoText}
                                    setUnfollowInvestorUID={setUnfollowInvestorUID}
                                    setFollowInvestorUID={setFollowInvestorUID}
                                />
                            ))}
                    </View>
                </ScrollbarWrapper>
                <ConfirmationMessage
                    text={investorHook.confirmationProps.text}
                    error={investorHook.confirmationProps.error}
                    visible={investorHook.confirmationVisible}
                    setVisible={investorHook.setConfirmationVisible}
                />
                {yesNoVisible ? (
                <View
                    style={[styles.blurScreen, { height: dimensions.height }]}
                />
                ) : null}
                <YesNoMessage visible={yesNoVisible} text={yesNoText} setVisible={setYesNoVisible} yesFunction={unfollowInvestor}/>
            </>
        );
    }
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        blurScreen: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            backgroundColor: theme.background,
            opacity: 0.75,
        },
        container: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            paddingTop: scaleSize(7),
            paddingLeft: scaleSize(7),
            paddingRight: Platform.OS === 'web' ? 0 : scaleSize(7),
            paddingBottom: scaleSize(7),
        },
    });
