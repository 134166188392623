import axios from '../baseClient';

import {
    LoginResponse,
    LoginParams,
    RegisterParams,
    RegistrationResponse,
    ValidateUsernameRequest,
    ValidateUsernameResponse,
    ValidatePhoneRequest,
    ValidatePhoneResponse,
    LoginIBParams,
    ConfirmEmailRequest,
    ConfirmEmailResponse,
    AffiliateParams,
    ValidateProfilePhoneRequest,
    AutoLoginParams,
    MultiaccountInfoResponse,
    TraderAccountsResponse,
    SwitchAccountResponse, LoginWithTokenParams,
} from './types';
import config from '~/config';
import { Platform } from 'react-native';

class AuthDataService {
    private static metaParams = {
        source: Platform.OS.toUpperCase(),
        apiVersion: config.API_VERSION,
        sourceVersion: config.SOURCE_VERSION,
        sourceType: Platform.OS.toUpperCase(),
        website: config.WEBSITE,
    };

    login(params: LoginParams) {
        console.log({
            ...AuthDataService.metaParams,
            ...params,
        })
        return axios.post<LoginResponse>('/api/24yiedlogin/login', {
            ...AuthDataService.metaParams,
            ...params,
        });
    }

    loginIB(params: LoginIBParams) {
        return axios.post<LoginResponse>('/api/24yiedlogin/loginIB', {
            ...AuthDataService.metaParams,
            ...params,
        });
    }

    loginAsCustomer(params: LoginIBParams) {
        return axios.post<LoginResponse>('/api/24yiedlogin/loginAsCustomer', {
            ...AuthDataService.metaParams,
            ...params,
        });
    }

    autoLogin(params: AutoLoginParams) {
        return axios.post<LoginResponse>('/api/24yiedlogin/autoLogin', {
            ...AuthDataService.metaParams,
            ...params,
        });
    }

    loginWithToken(params: LoginWithTokenParams) {
        return axios.post<LoginResponse>('/api/24yiedlogin/loginWithToken', {
            ...AuthDataService.metaParams,
            ...params,
        });
    }

    getMultiaccountInfo() {
        return axios.post<MultiaccountInfoResponse>('/api/24yiedlogin/getMultiaccountInfo', {});
    }

    getTraderAccounts() {
        return axios.post<TraderAccountsResponse>('/api/24yiedlogin/getTraderAccounts', {});
    }

    switchAccount(switchToAccountId: number) {
        return axios.post<SwitchAccountResponse>('/api/24yiedlogin/switchAccount', {switchToAccountId: switchToAccountId});
    }

    
    
    logout() {
        axios.post<void>('/api/24yiedlogin/logout', {});
    }

    registration(params: RegisterParams) {
        return axios.post<RegistrationResponse>('/api/24yieldregistrationservice/nosession/registerRealAccountLanding', {
            ...AuthDataService.metaParams,
            ...params,
        });
    }

    registrationAffiliate(params: RegisterParams, affiliateParams: AffiliateParams) {
        return axios.post<RegistrationResponse>('/api/24yieldregistrationservice/nosession/registerRealAccountLanding2', {
            ...AuthDataService.metaParams,
            ...params,
            ...affiliateParams
        });
    }

    validateUsername(validateUsernameRequest: ValidateUsernameRequest) {
        return axios.post<ValidateUsernameResponse>(
            '/api/24yieldregistrationservice/nosession/validateUsername',
            validateUsernameRequest
        );
    }

    validatePhone(validatePhoneRequest: ValidatePhoneRequest) {
        return axios.post<ValidatePhoneResponse>('/api/24yieldregistrationservice/nosession/validatePhone', validatePhoneRequest);
    }

    validateProfilePhone = function(validateProfilePhoneRequest: ValidateProfilePhoneRequest) {
        return axios.post<ValidatePhoneResponse>('/api/24yieldregistrationservice/nosession/validateFullPhone', validateProfilePhoneRequest);
    }

    confirmEmail(confirmEmailRequest: ConfirmEmailRequest) {
        return axios.post<ConfirmEmailResponse>('/api/24yieldregistrationservice/nosession/confirmEmail', {
            ...AuthDataService.metaParams,
            ...confirmEmailRequest,
        });
    }
    getEnabledAlchemy(country:string) {

        return axios.post<RegistrationResponse>('/api/24yieldregistrationservice/nosession/getEnabledAlchemy', {country: country, website: config.WEBSITE, platform: Platform.OS});

    }
}

export default new AuthDataService();
