import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import LoginAsCustomerForm from "../features/Authentication/LoginAsCustomerForm";

export default function LoginAsCustomerScreen() {
    return (
        <ScreenWrapper>
            <LoginAsCustomerForm />
        </ScreenWrapper>
    );
}
